//
// _table.scss
//

.table {
  .bdr-top-none {
    border-top: transparent;
  }
  .bdr-bottom-none {
    border-bottom: transparent;
  }
  &.bv-table-center-align {
    > thead > tr > th:not(:first-child),
    > tbody > tr > td:not([rowspan]) {
      text-align: center;
    }
  }
  &.bv-table-borderless {
    * {
      border: none;
    }
  }
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: transparent;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
