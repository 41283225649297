.dashboard-tabs {
    .nav-pills {
        background: #ffffff;
        border-radius: 20px;
        opacity: 1;
        padding: 0.5rem;
        width: fit-content;
        .nav-item {
            a {
                width: 170px;
                height: 55px;
                background: white;
                color: #00a290;
                text-align: center;
                font-size: 1.2rem;
                letter-spacing: 0.8px;
                border-radius: 15px;
                line-height: 2;
                margin-right: 0.25rem;
                &.active {
                    border-radius: 15px;
                    background: #00a290;
                    color: white;
                }
            }
        }
    }
}
.custom-tabs {
    display: flex;
    justify-content: center;
}
