.back-btn {
    background: #ffffff;
    border: 1px solid #00a290;
    border-radius: 10px;
    width: 170px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.72px;
    color: #00a290;
    height: 55px;
    line-height: 3;
    cursor: pointer;
}

/* Mobile Phones - Portrait */
@media (max-width: 480px) {
    .app-approval-code-cotainer,
    .app-add-manager-container,
    .app-refund-modal {
        margin: 0 !important;
        padding: 0 !important;
    }
}

/* Mobile Phones - Landscape */
@media (min-width: 481px) and (max-width: 767px) {
    /* CSS rules specific to mobile phones in landscape mode */
}

/* Tablets - Portrait */
@media (min-width: 768px) and (max-width: 991px) {
    /* CSS rules specific to tablets in portrait mode */
}

/* Tablets - Landscape */
@media (min-width: 992px) and (max-width: 1199px) {
    /* CSS rules specific to tablets in landscape mode */
}

/* Small Screens */
@media (min-width: 1200px) and (max-width: 1439px) {
    /* CSS rules specific to small screens*/
}
