.confirmation-modal{

  .confirmation-typo{
    text-align: center;
    font-size: 1.8rem !important;
    letter-spacing: 1.12px;
    color: #000000 !important;
    opacity: 1;
  }
}

.modal-dialog {
  max-width: 600px;
}

