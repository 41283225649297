.home-page-con{
  .login-btn-bg{
    background-color: white;
    &:hover{
      background-color: #00A290;
    }
  }
  .home-body{
    border-radius: 20px;
  }
  .custom-text-input .before-text-icon {
    position: absolute;
    top: 37%;
    left: 8%;
  }
}