.custom-text-input {
    position: relative;
    &:not(.hide-shadow) {
        input,
        select {
            box-shadow: 0px 3px 12px #706e6e29;
        }
    }
    input,
    select {
        border: 1px solid #00a290;
        border-radius: 10px;
        opacity: 1;
        font-size: 1rem;
        height: 55px;
        padding-left: 2.5rem;
        &:focus {
            border: 1px solid #00a290;
        }
        font-family: "Urbanist", sans-serif;
        font-weight: 500;
    }
    select {
        padding-right: 30px !important;
    }
    input {
        &[type="date"] {
            padding-right: 12px !important;
        }
    }
    &:not(.hide-default-icon) {
        input {
            padding-left: 47px;
            padding-right: 47px;
            &::placeholder {
                color: #2e2e30;
            }
            &[type="email"] {
                background: url("../../assets/images/envelop-icon.png") no-repeat 20px center #fff;
            }
            &[type="password"] {
                background: url("../../assets/images/lock-icon.png") no-repeat 20px center #fff;
            }
        }
    }
    textarea.form-control {
        min-height: 256px;
        resize: none;
        background-color: #eef9ff;
        border-color: #eef9ff;
        &::placeholder {
            color: #000;
        }
    }

    .input-icon {
        position: absolute;
        top: 50%; /* Position from the middle vertically */
        transform: translateY(-50%); /* Adjust for centering */
        right: 12px;
        span {
            font-size: 1.5rem;
            color: #00a290;
        }
    }

    .before-text-icon {
        position: absolute;
        top: 35%;
        left: 3%;
    }
}

.app-switch-input {
    &.sm {
        // Write as per need
    }

    &.md {
        // Write as per need
    }
    &.lg {
        width: 63px !important;
        height: 30px !important;
        padding: 4px !important;
    }
}

.switch-label {
    margin-bottom: 0 !important;
}
