.body-search {
    .form-control {
        width: 300px;
        height: 45px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding-left: 40px;
        padding-right: 20px;
        outline: none;
        font-weight: 500;
    }
    span {
        position: absolute;
        z-index: 10;
        font-size: 20px;
        line-height: 38px;
        left: 13px;
        top: 0;
    }
}
