//
// Google font - Poppins
//

// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&family=Urbanist:wght@400,500,600,700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&family=Urbanist:wght@400,500,600,700&display=swap");

// @import url(../../../fonts/aiapait/Urbanist/Urbanist-VariableFont_wght.ttf)

@font-face {
  font-family: 'Urbanist';
  src: url(../../../fonts/Urbanist/Urbanist-VariableFont_wght.ttf) format('truetype');
}