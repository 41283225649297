.managers-main-con {
    .managers-listing-btn {
        padding: 0.5rem 1.5rem;
        height: 35px;
        border: 1px solid #00a290;
        border-radius: 10px;
        opacity: 1;
        line-height: 0;
        text-align: center;
        letter-spacing: 0.56px;
        color: #00a290;
        font-size: 0.9rem;
        font-weight: 600;
        &:hover {
            background-color: transparent;
        }
    }

    .manager-active {
    }
    .manager-suspended {
    }

    .add-user-btn {
        text-align: center;
        letter-spacing: 0.8px;
        font-size: 1rem;
        width: 180px;
        height: 45px;
    }
}
