.refund-main-con {
    .refund-table-action {
        text-align: center;
        font-size: 1rem;
        letter-spacing: 0.64px;
        color: #ffffff;
        background: #00a290;
        border-radius: 10px;
        padding: 0.5rem 1.5rem;
        border: none;
    }
}
