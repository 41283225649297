.footer-con{
  color: #00675B;
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.28px;
  color: #00675B;
  opacity: 1;
  a{
    color: #00675B;
    text-decoration: underline !important;
  }
}