@import '../../assets/scss/theme/theme1/theme.scss';

.custom-btn {
    box-shadow: 0px 5px 10px 0px #D4D4D4;
    // border: 1px solid transparent;
    border-radius: 10px;
    opacity: 1;
    height: 55px;
    letter-spacing: 0.72px;
    font-size: 1.1rem;
    &.grad-primary {
        background: transparent linear-gradient(121deg, #7c2c83 0%, #d65189 100%);
        box-shadow: 0px 3px 12px #706e6e29;
        border-radius: 4px;
        min-width: 345px;
        min-height: 40px;
        border-color: transparent;
    }
    &.custom-btn-lg {
        min-width: 200px;
    }
    &.custom-btn-lg-secondary {
        min-width: 200px;
        border: 1px solid $theme-primary-color;
        color: $theme-primary-color;
        background: white;

    }
    &.custom-btn-md {
        min-width: 142px;
    }
    &.custom-btn-sm {
        min-width: 129px;
    }
    &.custom-btn-lg {
        min-width: 200px;
    }
    &.shadow-theme {
        box-shadow: -7px 0px 10px #00000029;
        // min-width: 96px;
    }
}
