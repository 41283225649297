/* Mobile Phones - Portrait */
@media (max-width: 480px) {
    .app-login-container,
    .app-change-password-container {
        margin: 0 !important;
        padding: 0 !important;
    }
}

/* Mobile Phones - Landscape */
@media (min-width: 481px) and (max-width: 767px) {
    /* CSS rules specific to mobile phones in landscape mode */
}

/* Tablets - Portrait */
@media (min-width: 768px) and (max-width: 991px) {
    /* CSS rules specific to tablets in portrait mode */
}

/* Tablets - Landscape */
@media (min-width: 992px) and (max-width: 1199px) {
    /* CSS rules specific to tablets in landscape mode */
}

/* Small Screens */
@media (min-width: 1200px) and (max-width: 1439px) {
    /* CSS rules specific to small screens*/
}
