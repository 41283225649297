.app-data-table {
    thead {
        color: #fff;

        th {
            border-top: none;
            border-bottom: 1px solid #00a290;

            font-weight: normal;
            background-color: transparent;
        }
    }
    tbody {
        tr * {
            background-color: transparent;
            text-align: left;
            font-size: 1.1rem;
            letter-spacing: 0.72px;
            color: #000000;
            font-weight: 500;
            // padding: 1rem 0.75rem;
            line-height: 2;
        }
        .bold-font {
            font-weight: 700;
        }
        .bold-600 {
            font-weight: 600;
        }
    }
    td {
        vertical-align: middle;
    }
}
.data-table-head {
    display: flex;
    align-items: center;
    flex-direction: row;

    &.sortable {
        user-select: none;
        cursor: pointer;
    }

    & i {
        margin-left: 1px;
        font-size: 15px;

        &.bx-sort {
            opacity: 0.5;
        }
    }

    & .filter-value {
        font-size: 10px;
        margin-left: 3px;
        font-style: italic;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table-min-height {
    min-height: 350px;
}

.table-loader-container {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-filter-menu {
    font-size: 20px;
    cursor: pointer;
}

.column-filter-item {
    padding-top: 6px;
    padding-bottom: 6px;
    label,
    input {
        cursor: pointer;
    }
}

.column-filter-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}

span.column-title {
    font-weight: 700;
    color: #2795ae;
    text-align: left;
    letter-spacing: 0.84px;
    color: #000000;
    text-transform: uppercase;
    font-size: 0.9rem;
}
