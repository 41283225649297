// @import "../../assets/scss/theme/theme1/theme.scss";

.date-picker-input {
    border-radius: 10px !important;
    padding: 5px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;

    height: 45px;
    width: 150px;
    &::placeholder {
        color: #a5a5a5;
        font-size: 16px;
        font-weight: 400;
    }
}

.react-datepicker__close-icon {
    margin-right: 7px;
}

.react-datepicker__close-icon::after {
    background-color: #00a290 !important;
}
