.filter-btn {
    width: fit-content;
    height: 45px;
    font-size: 18px;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #00a290;
    color: #00a290;
    cursor: pointer;
}
.dropdown-container {
    min-width: 250px;
    margin: 0 8px 0 8px;
}

@media (max-width: 767px) {
    .date-range-container div {
        margin: 4px 0 4px 0;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .date-range-container div {
        margin: 4px 0 4px 0;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .date-range-container {
        flex-wrap: nowrap !important;
    }
    .date-range-container div {
        margin: 0 5px 0 5px;
    }
}
@media (min-width: 1200px) {
    .date-range-container {
        flex-wrap: nowrap !important;
    }
    .date-range-container div {
        margin: 0 5px 0 5px;
    }
}
