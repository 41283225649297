.input-group-custom {
    .prepend-text {
        border: 1px solid #00a290;
        border-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background: #ffffff;
        text-align: left;
        letter-spacing: 0.64px;
        color: #00a290;
        opacity: 1;
        font-size: 1rem;
        font-family: "Urbanist", sans-serif;
        width: 25%;
    }
    .main-con-input {
        width: 75%;
    }
    .input-group-shadow {
        input {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            box-shadow: none;
        }
    }
}
/* Mobile Phones - Portrait */
@media (max-width: 480px) {
    .prepend-text {
        display: none;
    }
    .main-con-input {
        width: 100% !important;
        & .custom-text-input {
            & .form-control {
                border-radius: 10px;
            }
        }
    }
}

/* Mobile Phones - Landscape */
@media (min-width: 481px) and (max-width: 767px) {
    /* CSS rules specific to mobile phones in landscape mode */
}

/* Tablets - Portrait */
@media (min-width: 768px) and (max-width: 991px) {
    /* CSS rules specific to tablets in portrait mode */
}

/* Tablets - Landscape */
@media (min-width: 992px) and (max-width: 1199px) {
    /* CSS rules specific to tablets in landscape mode */
}

/* Small Screens */
@media (min-width: 1200px) and (max-width: 1439px) {
    /* CSS rules specific to small screens*/
}
