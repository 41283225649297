.profile-menu-con {
    .menu-icon-con {
        background: #00a290;
        padding: 17px 10px;
        color: white;
        border-radius: 10px;
        i {
            font-size: 2.5rem;
        }
    }

    .dropdown-menu-end {
        box-shadow: 0px 12px 18px #00000014;
        border: 1px solid #00a290;
        border-radius: 20px;
        opacity: 1;
        background-color: #fff;
        .dropdown-item {
            text-align: left;
            letter-spacing: 0.32px;
            color: #000000;
            opacity: 1;
            font-family: "Urbanist", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            padding: 0.75rem 2rem;
            &:hover {
                border-radius: 10px;
            }
        }
    }
}

@media (max-width: 480px) {
    .logout-modal {
        padding: 0 !important;
    }
    .logout-modal-no-btn {
        margin-left: 0 !important;
    }
}

/* Mobile Phones - Landscape */
@media (min-width: 481px) and (max-width: 767px) {
    /* CSS rules specific to mobile phones in landscape mode */
}

/* Tablets - Portrait */
@media (min-width: 768px) and (max-width: 991px) {
    /* CSS rules specific to tablets in portrait mode */
}

/* Tablets - Landscape */
@media (min-width: 992px) and (max-width: 1199px) {
    /* CSS rules specific to tablets in landscape mode */
}

/* Small Screens */
@media (min-width: 1200px) and (max-width: 1439px) {
    /* CSS rules specific to small screens*/
}
