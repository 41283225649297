@import "./bootstrap.scss";
@import "../../theme.scss";
@import "./variables";
@import "../../app.scss";
@import "functions";

body {
    * {
        outline: none;
    }
    font-family: "Urbanist", sans-serif;
    background-color: $theme-body-bg-color;
}

.fw-600 {
    font-weight: $font-weight-semibold;
}

.pointer {
    cursor: pointer;
}

.clr-theme-primary {
    color: $theme-primary-color !important;
}

.clr-theme-primary-light {
    color: $theme-primary-light-color !important;
}

.clr-theme-secondary {
    color: $theme-secondary-color;
}

.clr-theme-secondary-light {
    color: $theme-secondary-light-color;
}

.clr-theme-blue {
    color: $blue;
}

.bg-grad-primary {
    background: transparent
        linear-gradient(53deg, $theme-secondary-color 0%, $theme-primary-color 100%) 0% 0%;
}

.bg-theme-secondary {
    background-color: $theme-secondary-color !important;
}

.bg-theme-secondary-light {
    background-color: $theme-secondary-light-color !important;
}

.bg-theme-primary-light {
    background-color: $theme-primary-light-color !important;
}

.bg-clr-blue {
    background-color: #3785ae;
}

.clr-blue {
    color: #3785ae !important;
}

.clr-theme-red {
    color: $red;
}

.bg-red {
    background-color: #d84243 !important;
}

.bg-yellow {
    background-color: #e5bc17 !important;
}

.clr-yellow {
    color: #dcb30e;
}

.clr-purple {
    color: #7c2c83;
}

.bg-grad-theme1 {
    background: transparent
        linear-gradient(58deg, $cs-theme-1-primary 0%, $cs-theme-1-secondary 100%) 0% 0%;
}

.bg-grad-theme2 {
    background: transparent
        linear-gradient(58deg, $cs-theme-2-primary 0%, $cs-theme-2-secondary 100%) 0% 0%;
}

.bg-grad-theme3 {
    background: transparent
        linear-gradient(58deg, $cs-theme-3-primary 0%, $cs-theme-3-secondary 100%) 0% 0%;
}

.bg-grad-theme4 {
    background: transparent
        linear-gradient(58deg, $cs-theme-4-primary 0%, $cs-theme-4-secondary 100%) 0% 0%;
}

.bg-grad-theme5 {
    background: transparent
        linear-gradient(58deg, $cs-theme-5-primary 0%, $cs-theme-5-secondary 100%) 0% 0%;
}

.bg-grad-theme6 {
    background: transparent
        linear-gradient(58deg, $cs-theme-6-primary 0%, $cs-theme-6-secondary 100%) 0% 0%;
}

.bv-data-table {
    thead {
        background-color: #f2eeff;
    }
}

.cs-options-top-right {
    top: 0;
    right: 0;
    z-index: 1;
}

.vertical-menu {
    div.navbar-brand-box {
        // background: none !important;
        background: $theme-primary-color;
    }
    // background: url("../../../images/theme-one-sidebar-bg.png") !important;
}

.bv-profile {
    .bv-text-input {
        input,
        select {
            background-color: $theme-secondary-color !important;
        }
    }
}

.cs-select-dropdown {
    &-green {
        select.form-select {
            background-color: $light-green !important;
            color: $dark-green !important;
        }
    }
}

.bv-right-hr {
    color: #2e2e30;
    &::after {
        content: "";
        display: inline-block;
        height: 1px;
        background-color: #b8b8b8;
        width: 35%;
        margin-left: 14px;
    }
}

.cs-equal-card-wrap {
    .cs-equal-card-left {
        max-height: 380px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
    .cs-equal-card-right-1 {
        max-height: 180px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
    .cs-equal-card-right-2 {
        max-height: 180px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
}

.bv-auth-code {
    input {
        caret-color: $theme-secondary-color;
        &:not(:focus) {
            border-color: $theme-primary-color;
        }
        &:focus {
            border-color: $theme-secondary-color;
        }
    }
}

.theme-primary-color {
    color: $theme-primary-color;
}

/* Customize scrollbar width and background color */
::-webkit-scrollbar {
    height: 5px;
    width: 10px;
    background-color: #f0f0f0;
}

/* Customize scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: $theme-primary-color;
    border-radius: 10px;
}

/* Customize scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: $theme-primary-color;
}

/* Customize scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

// Trancations colors for leapcart

.refunded-status-color {
    color: #00a290 !important;
}
.captured-status-color {
    color: #0046a2 !important;
}
.failed-status-color {
    color: #a20000 !important;
}
.refund-in-progress-status-color {
    color: #0eb4ce !important;
}

@media only screen and (max-width: 1634px) {
    .cs-user-details-approve-user-btns {
        .bv-btn.bv-btn-lg {
            min-width: auto;
        }
    }
}
